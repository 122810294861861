<template>
  <div :class="{ rtl: isLtr }" class="bg-img-default">
    <div class="md:w-1/2 mt-6 sm:mt-10">
      <div class="text-center mb-8">
        <h1
          :class="{ 'text-rose': isLtr, 'text-primary': isRtl }"
          class="text-3xl font-black"
        >
          {{ $t('web_title2') }}
        </h1>
        <p class="text-xs mt-2 text-gray-700">
          {{ $t('login_information') }}
        </p>
      </div>
      <div class="w-64 mx-auto">
        <form
          class="from-purple-400 to-indigo-300 bg-gradient-to-bl rounded-lg p-4"
          @submit.prevent="gotoNextPage"
        >
          <div class="text-right mb-2">
            <label class="text-white text-xs" for="name">{{
              $t('input_name')
            }}</label>
            <input
              class="bg-white w-full outline-none mt-px pr-2 py-1 rounded-2xl"
              type="text"
              id="name"
            />
          </div>
          <div class="relative text-right mb-2">
            <label class="text-white text-xs" for="password">{{
              $t('input_password')
            }}</label>
            <input
              class="bg-white w-full outline-none mt-px pr-2 py-1 rounded-2xl"
              :type="show ? 'text' : 'password'"
              id="password"
            />
            <div
              @click="show = !show"
              class="cursor-pointer absolute top-8 ml-2 flex left-0"
            >
              <p class="text-xs ml-1">show</p>
              <i v-if="show" class="fas fa-eye"></i>
              <i v-else class="fas fa-eye-slash"></i>
            </div>
          </div>
          <div class="text-center">
            <router-link :to="{ name: 'MainPage' }">
              <button
                class="font-medium px-16 py-1 rounded-2xl outline-none bg-lime-400 my-2 w-full"
              >
                {{ $t('login') }}
              </button>
            </router-link>
            <h3 class="text-white font-black text-base">
              {{ $t('contact_support') }}
            </h3>
            <small v-if="isRtl" class="block text-white text-xs">
              به منظور دریافت اطلاعات ورود با پشتیبانی تماس بگیرید
            </small>
            <button
              class="text-white border border-white font-extrabold px-14 py-1 rounded-2xl outline-none bg-pink-500 mt-1 w-full"
            >
              {{ $t('online_supportrt') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      show: false,
    }
  },
}
</script>

<style>
.bg-img-default {
  background-image: url('../assets/images/theme/docors.png');
  background-repeat: no-repeat;
  background-size: 450px auto;
  background-position-x: 20%;
  background-position-y: theme('spacing.10');
}
</style>
